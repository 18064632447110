"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.check = check;
var metrics_1 = require("k6/metrics");
var checkRate = new metrics_1.Rate('checks');
function check(value, checkers, tags) {
    var results = Object.entries(checkers).map(function (_a) {
        var key = _a[0], checker = _a[1];
        function reportResult(value) {
            checkRate.add(value ? 1 : 0, Object.assign({}, tags, {
                check: key,
            }));
            return value;
        }
        if (typeof checker === 'function') {
            var result = checker(value);
            if (result instanceof Promise) {
                return result.then(reportResult);
            }
            return reportResult(result);
        }
        if (checker instanceof Promise) {
            return checker.then(reportResult);
        }
        return reportResult(checker);
    });
    if (results.some(function (result) { return result instanceof Promise; })) {
        return Promise.all(results).then(function (results) { return results.every(Boolean); });
    }
    return results.every(Boolean);
}
